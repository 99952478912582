.App {
  text-align: center;
}
.mb-5{
  margin-bottom: 5px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.demo-logo {
       max-width: 254px;
    height: 100%;
    border-radius: 6px;
    margin-inline-end: 24px;
}
.demo-logo img {
   height: 62px;
    padding: 3px
}

.shadow{
  box-shadow: 1px -2px 0px 0 rgb(0 0 0 / 0%), 0px 1px 8px 0 rgb(0 0 0 / 17%) !important
}